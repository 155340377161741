/* global kjrecaptchav3_ajaxUrl, kjrecaptchav3_siteKey, psemailsubscription_subscription */
const {$, grecaptcha} = window;

let $newsletterForm;

$(() => {
  $newsletterForm = $('.block_newsletter form');
  $newsletterForm.off('submit');

  $newsletterForm.submit((event) => {
    event.preventDefault();

    executeReCAPTCHA(
      'newsletter',
      () => {
        submitNewsletterSubscription();

        return false;
      },
      (error) => {
        $newsletterForm.prepend(
          `<p class="alert alert-danger block_newsletter_alert">${error}</p>`,
        );

        return false;
      },
    );
  });
});

function executeReCAPTCHA(action, success, fail) {
  grecaptcha.ready(() => {
    grecaptcha.execute(kjrecaptchav3_siteKey, {action}).then((token) => {
      $.get(
        kjrecaptchav3_ajaxUrl,
        {
          action: 'verify',
          response_token: token,
        },
        () => {
          success();
        },
      ).fail((response) => {
        fail(response.responseText);
      });
    });
  });
}

function submitNewsletterSubscription() {
  $('.block_newsletter_alert').remove();

  $.ajax({
    type: 'POST',
    dataType: 'JSON',
        url: psemailsubscription_subscription, // eslint-disable-line
    cache: false,
    data: $newsletterForm.serialize(),
    success(data) {
      $newsletterForm.prepend(
        `<p class="alert ${
          data.nw_error ? 'alert-danger' : 'alert-success'
        } block_newsletter_alert">${data.msg}</p>`,
      );
    },
    error(err) {
      console.log(err);
    },
  });
}
